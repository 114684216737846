import React from "react";
import Layout from "@layout";
import SEO from "@components/seo";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils/functions";
import Footer from "../layouts/footer/layout-01";
import Header from "../layouts/header/layout-dark";
import BlogGridSection from "../container/blog-page/layout-01";

const BlogGridPage = ({ data }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO title="Home page" pathname="/" />
            <Header data={{ ...globalContent?.["menu"] }} />
            <BlogGridSection
                data={{
                    ...content?.["latest-section"],
                    items: data?.latestPosts?.nodes,
                }}
            />
            <Footer data={{ ...globalContent?.["footer"] }} />
        </Layout>
    );
};

BlogGridPage.propTypes = {
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        latestPosts: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export const query = graphql`
    query BBlogGridPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        page(title: { eq: "home" }, pageType: { eq: homepage }) {
            content {
                ...PageContentAll
            }
        }
        latestPosts: allArticle(
            sort: { fields: postedAt___date, order: DESC }
        ) {
            nodes {
                ...Articles
                image {
                    alt
                    src {
                        childImageSharp {
                            gatsbyImageData(
                                height: 293
                                quality: 100
                                width: 476
                            )
                        }
                    }
                }
            }
        }
    }
`;
export default BlogGridPage;
